<template>
  <div class="charity-page">
      

    <!-- Top Section -->

    <h1 class="charity-title fade-in">Saving Those Who Can't Save Themselves</h1>
    <section class="fade-in top-section">
      <img src="@/assets/charity.jpg" alt="Charity Image" style="width: 400px;height: 550px;z-index: 99;" />
      <div class="charity-text">
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Non eum doloribus est incidunt dicta porro esse iste! Voluptate modi sit qui
          suscipit in quos asperiores consequuntur deleniti? Placeat, repellendus expedita? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Inventore pariatur aperiam quisquam dolores nostrum perferendis iusto quo! Illo explicabo voluptas natus, tempora cumque officiis! Molestiae nihil dolores quo vero aliquam.
        </p>
      </div>
    </section>

    <!-- Data Section -->
    <div class="data-section fade-in">
      <h2 class="patient-records-title">Patient Records</h2>
      <table class="patient-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Gender</th>
            <th>Age</th>
            <th>Status</th>
            <th>Tazkira</th>
            <th>Sickness</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(patient, index) in paginatedPatients" :key="index">
            <td>{{ patient.name }}</td>
            <td>{{ patient.gender }}</td>
            <td>{{ patient.age }}</td>
            <td>{{ patient.status }}</td>
            <td>{{ patient.tazkira }}</td>
            <td>{{ patient.sickness }}</td>
          </tr>
        </tbody>
      </table>

      <!-- Paginator -->
      <div class="pagination">
        <button @click="previousPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
      </div>
      </div>
      <!-- Images/Videos -->
       <div class="">
        <div class="media-section">
          
    <h2 class="section-title">Our Impact</h2>
    
    <div class="image-gallery">
      <div
        class="image-card"
        v-for="(image, index) in patientImages"
        :key="index"
      >
        <img :src="require(`@/assets/${image.src}`)" :alt="image.alt"  />
        <div class="overlay"><p>{{ image.alt }}</p></div>
      </div>
       </div>

    </div>
  </div>

    <!-- Animated Statistics Boxes -->
    <div class="statistics-section">
      <div class="stat-box">
        <NumberAnimation
          ref="number1"
          :from="0"
          :to="36"
          :format="theFormat"
          :duration="1"
          autoplay
          easing="linear"
        />
      </div>
    </div>
  </div>
 

</template>

<script>
import NumberAnimation from 'vue-number-animation';

export default {
  components: {
    NumberAnimation
  },
  
  data() {
    return {
      patients: [
        { name: 'John Doe', gender: 'Male', age: 32, status: 'Recovered',tazkira:'1392-339123-2312',sickness:'Anxiety' },
        { name: 'Jane Smith', gender: 'Female', age: 29, status: 'Under Treatment',tazkira:'1398-535932-1830',sickness:'Unhappy' },
         { name: 'John Doe', gender: 'Male', age: 32, status: 'Recovered',tazkira:'1392-339123-2312',sickness:'Anxiety' },
        { name: 'Jane Smith', gender: 'Female', age: 29, status: 'Under Treatment',tazkira:'1398-535932-1830',sickness:'Unhappy' },
         { name: 'John Doe', gender: 'Male', age: 32, status: 'Recovered',tazkira:'1392-339123-2312',sickness:'Anxiety' },
        { name: 'Jane Smith', gender: 'Female', age: 29, status: 'Under Treatment',tazkira:'1398-535932-1830',sickness:'Unhappy' },
        // Add more patients as needed
      ],
      currentPage: 1,
      patientsPerPage: 5,
      patientImages: [
        { src: "nurse.jpg", alt: "Patient 1" },
        { src: "whyImg.jpg", alt: "Patient 3" },
        { src: "therapist.webp", alt: "Patient 3" },
         { src: "nurse.jpg", alt: "Patient 1" },
        { src: "manager.jpg", alt: "Patient 2" },
        { src: "therapist.webp", alt: "Patient 3" },
        { src: "manager.jpg", alt: "Patient 2" },
        { src: "whyImg.jpg", alt: "Patient 3" },
        { src: "whyImg.jpg", alt: "Patient 3" },
        { src: "whyImg.jpg", alt: "Patient 3" },
        // Add more images as needed
      ],
    };
  },
  mounted(){
    this.observeSections();
  },

  computed: {
    totalPages() {
      return Math.ceil(this.patients.length / this.patientsPerPage);
    },
    paginatedPatients() {
      const start = (this.currentPage - 1) * this.patientsPerPage;
      const end = start + this.patientsPerPage;
      return this.patients.slice(start, end);
    }
  },
  methods: {
    
    observeSections() {

      const options = {

        threshold: 0.2,

      };



      const observer = new IntersectionObserver((entries) => {

        entries.forEach((entry) => {

          if (entry.isIntersecting) {

            entry.target.classList.add('in-view');

          }

        });

      }, options);



      document.querySelectorAll('.fade-in').forEach((section) => {

        observer.observe(section);

      })},
    theFormat(number) {
      return number.toFixed(0);
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    }
  },
};
</script>

<style scoped>
.charity-page {
  padding: 20px;
  position: relative; /* Ensure child elements with absolute positioning are positioned relative to this */
}

.background {
  position: relative;
  background-color: #f3f8fb;
  padding: 40px 20px;
  overflow: hidden;
}

.animated-lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(45deg,
          rgba(197, 193, 193, 0.1),
          rgba(233, 231, 231, 0.1) 10px,
          transparent 10px,
          transparent 20px);
  animation: move-lines 20s linear infinite;
  z-index: 1; /* Ensure it stays behind the other content */
}

/* Keyframes for moving the lines */
@keyframes move-lines {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 200% 200%;
  }
}

.media-section {
  margin-top: 40px;
  z-index: 2; /* Ensure it's above the animated lines */
  padding: 20px;
  padding: 6px;
}

.image-gallery {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Five images per row */
  gap: 20px; /* Gap between cards */
}

.image-card {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-card img {
  width: 100%;
  height: 200px;
  display: block;
  object-fit: cover;
}

.image-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-card:hover .overlay {
  opacity: 1;
}

/* Other styles as previously defined */




.in-view {
  opacity: 1;
  transform: translateY(0);
}
.patient-records-title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  color: #10767b;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #10767b;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-in.in-view {
  opacity: 1;
  transform: translateY(0);
}


.charity-title {
  text-align: center;
  color: #059ca1;
}

.top-section {
  margin: 10px;
  padding: 15px;
  display: flex;
}

.top-section img {
  margin-left: 20%;
  margin-top: 20px;
}

.top-section .charity-text {
  width: 250px;
  margin-left: 10%;
  letter-spacing: 3px;
  line-height: 1.5;
}

.data-section {
  margin-top: 40px;
}

.patient-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.patient-table th, .patient-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.patient-table th {
  background-color: #10767b;
  color: #fff;
}

.patient-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.patient-table tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #10767b;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.media-section {
  margin-top: 40px;
  text-align: center;
}

.media-section img,
.media-section video {
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
}

.statistics-section {
  margin-top: 60px;
  display: flex;
  justify-content: space-around;
}

.stat-box {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 200px;
  transition: background-color 0.5s ease;
}

.stat-box h3 {
  margin: 0;
  font-size: 1.5rem;
}

.stat-box p {
  margin: 10px 0 0;
  font-size: 2rem;
}
.z-ev{
  z-index: 900;
}
</style>
