<template>
  <div class="content">
    <div class="services-page">
      <header class="hero">
        <h1 class="hero-title">Comprehensive Psychiatric Care</h1>
        <p class="hero-subtitle">Helping you find the path to mental wellness</p>
        <button class="cta-button">Learn More</button>
      </header>

      <section class="services-section">
        <h2 class="section-title">Our Services</h2>
        <div class="services-grid">
          <div v-for="service in services" :key="service.id" class="service-card" @click="scrollToService(service.id)">
            <div class="service-icon-wrapper">
              <component :is="service.icon" class="service-icon" />
            </div>
            <h3 class="service-title">{{ service.title }}</h3>
          </div>
        </div>
      </section>

      <div v-for="(service, index) in services" :key="service.id">
        <section :id="`service-${service.id}`" :class="`service-details ${index % 2 === 0 ? 'even' : 'odd'}`">
          <div class="service-content" :class="index % 2 === 0 ? 'slide-left' : 'slide-right'">
            <h2 class="service-title">{{ service.title }}</h2>
            <p class="service-description">{{ service.description }}</p>
          </div>
          <div class="service-image-wrapper">
            <img :src="require(`@/assets/${service.imageSrc}`)" :alt="service.title" class="service-image" />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { CameraIcon, HeartIcon, ClipboardIcon, ChartPieIcon } from '@heroicons/vue/outline'

export default {
  name: 'ServicesPage',
  data() {
    return {
      services: [
        {
          id: 1,
          icon: CameraIcon,
          title: 'Diagnostic Assessments',
          description: 'Our comprehensive assessments help identify the root causes of mental health issues.',
          imageSrc: 'impatient_therapy.jpg'
        },
        {
          id: 2,
          icon: HeartIcon,
          title: 'Inpatient Treatment',
          description: 'Our inpatient program provides 24/7 care and support for those in acute crisis.',
          imageSrc: 'check_up.jpg'
        },
        {
          id: 3,
          icon: ClipboardIcon,
          title: 'Outpatient Therapy',
          description: 'Customized outpatient therapy plans help patients develop coping strategies.',
          imageSrc: 'ourgoal.jpg'
        },
        {
          id: 4,
          icon: ChartPieIcon,
          title: 'Group Counseling',
          description: 'Our group counseling sessions foster connection and peer support.',
          imageSrc: 'therapy_2.jpg'
        }
      ]
    }
  },
  methods: {
    scrollToService(id) {
      const element = document.getElementById(`service-${id}`)
      element.scrollIntoView({ behavior: 'smooth' })
    }
  },
  mounted() {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('animate');
        observer.unobserve(entry.target);
      }
    });
  });

  document.querySelectorAll('.service-details').forEach((section) => {
    observer.observe(section);
  });
}

}
</script>

<style scoped>
.content {
  padding-top: 14px;
}

.services-page {
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #fff;
}

.hero {
  background-color: #008080;
  color: #fff;
  padding: 5rem 2rem;
  text-align: center;
  animation: fadeIn 0.8s ease;
}

.hero-title {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.cta-button {
  background-color: #fff;
  color: #008080;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.cta-button:hover {
  background-color: #008080;
  color: #fff;
}

.services-section {
  padding: 2rem 1rem;
  animation: fadeIn 0.8s ease;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1.5rem;
}

.service-card {
  background-color: #f5f5f5;
  border-radius: 0.5rem;
  padding: 1.5rem;
  text-align: center;
  transition: transform 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
}

.service-card:hover {
  transform: translateY(-10px);
  background-color: #e0e0e0;
}

.service-icon-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.service-icon {
  width: 1.8rem;
  height: 1.8rem;
  color: #008080;
}

.service-title {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.service-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 2rem;
  background-color: #f5f5f5;
}

.service-details.even {
  background-color: #e0e0e0;
  flex-direction: row-reverse;
}

.service-details.odd {
  background-color: #f5f5f5;
}

.service-content {
  flex-basis: 50%;
  padding-right: 2rem;
}

.service-content.slide-left {
  animation: slideFromLeft 1s ease;
}

.service-content.slide-right {
  animation: slideFromRight 1s ease;
}

.service-description {
  font-size: 1.2rem;
}

.service-image-wrapper {
  flex-basis: 50%;
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.service-image {
  width: 90%;
  height: auto;
  border-radius: 0.5rem;
}
.service-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 2rem;
  background-color: #f5f5f5;
  opacity: 0;
  transform: translateX(100px); /* Start outside viewport */
  transition: transform 0.8s ease, opacity 0.8s ease;
}

.service-details.even {
  background-color: #e0e0e0;
  flex-direction: row-reverse;
  transform: translateX(-100px); /* Slide in from the left */
}

.service-details.odd {
  background-color: #f5f5f5;
}

.service-details.animate {
  opacity: 1;
  transform: translateX(0); /* Slide to position */
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .service-details {
    flex-direction: column; /* Stack content vertically */
    text-align: center;
    padding: 2rem 1rem;
  }

  .service-content {
    padding-right: 0;
    margin-bottom: 1rem;
  }

  .service-image-wrapper {
    width: 100%;
  }

  .service-image {
    width: 100%;
    height: auto;
  }
}

</style>
