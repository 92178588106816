import { createApp } from 'vue';
import App from './App.vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'; // Import Element Plus styles
import router from './router';
import NumberAnimation from "vue-number-animation";

const app = createApp(App);
app.use(ElementPlus);
app.use(NumberAnimation)
app.use(router)
app.mount('#app');
