<template>
  <footer class="footer">
    <div class="footer-container">
      <div class="footer-section contact-info">
        <h3>Contact Us</h3>
        <p><strong>Address:</strong> Baharistan, Near Burj Bar station, Kabul, Afghanistan</p>
        <p><strong>Phone:</strong> (93) 785-611-999</p>
        <p><strong>Email:</strong> axon.hsp@gmail.com</p>
      </div>

      <div class="footer-section quick-links">
        <h3>Quick Links</h3>
        <ul>
          <li>

            <router-link to="/home">Home</router-link>
          </li>
          <li>

            <router-link to="/about">About</router-link>
          </li>
          <li>
            
            <router-link to="/contact">Contact</router-link>
          </li>
          <li>

            <router-link to="/services">Services</router-link>
          </li>
          <li>

            <router-link to="/charity">Charity</router-link>
          </li>
        </ul>
      </div>

      <div class="footer-section social-media">
        <h3>Follow Us</h3>
        <div class="social-icons">
          <a href="#" target="_blank" rel="noopener"><img src="@/assets/facebook.png" alt="Facebook"></a>
          <a href="#" target="_blank" rel="noopener"><img src="@/assets/twitter.png" alt="Twitter"></a>
          <a href="#" target="_blank" rel="noopener"><img src="@/assets/instagram.png" alt="Instagram"></a>
          <a href="#" target="_blank" rel="noopener"><img src="@/assets/linkedin.png" alt="LinkedIn"></a>
        </div>
      </div>
    </div>
    <div class="footer-bottom"> 
      <p>&copy; 2024 Axon Hospital. All rights reserved.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>


<style scoped>
.footer {
  background-color: #10767b;
  color: white;
  padding: 40px 20px;
  margin-top: 40px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px; /* Add space between sections */
}

.footer-section {
  flex: 1;
  margin: 10px;
  min-width: 250px; /* Increased to ensure better alignment */
}

.footer-section h3 {
  font-size: 22px; /* Slightly larger for emphasis */
  margin-bottom: 15px;
  color: #ffffff;
  border-bottom: 2px solid #f5f5f5; /* Add a subtle underline effect */
  padding-bottom: 5px;
}

.footer-section p,
.footer-section a {
  font-size: 16px;
  color: #f5f5f5;
  margin-bottom: 10px;
  text-decoration: none;
  line-height: 1.6; /* Improve readability */
}

.footer-section a:hover {
  text-decoration: underline;
  color: #ffffff; /* Highlight link on hover */
}

.social-icons {
  display: flex;
  gap: 10px; /* Ensure consistent spacing between icons */
}

.social-icons img {
  width: 30px;
  height: 30px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.social-icons img:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.5); /* Add a subtle glow on hover */
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 10px;
}

.footer-bottom p {
  font-size: 14px;
  color: #f5f5f5;
}

/* Added responsive styles */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column; /* Stack sections vertically */
    align-items: center;
  }

  .footer-section {
    text-align: center; /* Center-align text for better presentation */
    min-width: 100%; /* Expand sections to full width */
  }

  .social-icons {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 20px 10px; /* Reduce padding for small screens */
  }

  .footer-section h3 {
    font-size: 20px;
  }

  .footer-section p,
  .footer-section a {
    font-size: 14px; /* Slightly smaller text for readability */
  }

  .social-icons img {
    width: 25px;
    height: 25px; /* Adjust icon size for smaller screens */
  }

  .footer-bottom p {
    font-size: 12px;
  }
}

li {
  padding: 4px;
  list-style-type: none; /* Remove default bullet points */
}

li::before {
  content: "•"; /* Add custom bullet */
  color: #ffffff;
  padding-right: 5px;
}
</style>
