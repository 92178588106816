<template>
    <el-carousel :interval="4000" height="780px" indicator-position="outside">
        <el-carousel-item v-for="(item, index) in carouselItems" :key="index">
            <div class="carousel-wrapper">
                <div class="carousel-background" :style="{ backgroundImage: `url(${require(`@/assets/${item.image}`)})` }"></div>
                <div class="carousel-text">
                    <h2>{{ item.title }}</h2>
                    <p>{{ item.description }}</p>
                </div>
            </div>
        </el-carousel-item>
    </el-carousel>
</template>

<script>
export default {
    data() {
        return {
            carouselItems: [
                {
                    image: 'therapy.jpg',
                    title: 'Comprehensive Therapy Services',
                    description: 'Providing a wide range of therapy services tailored to your needs.',
                },
                {
                    image: 'lab.jpg',
                    title: 'Advanced Diagnostic Labs',
                    description: 'State-of-the-art laboratories for accurate and timely diagnoses.',
                },
                {
                    image: 'therapy.jpg',
                    title: 'Personalized Care',
                    description: 'Our expert team offers personalized care for every patient.',
                },
                {
                    image: 'ipd.jpg',
                    title: 'In-Patient Department',
                    description: 'Comfortable and well-equipped facilities for in-patient care.',
                },
            ],
        };
    },
};
</script>

<style scoped>
.carousel-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.carousel-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease;
}

.carousel-wrapper:hover .carousel-background {
    transform: scale(1.05); /* Slight zoom on hover */
}

.carousel-text {
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
    opacity: 1; /* Ensure it's visible by default */
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.carousel-text h2 {
    margin: 0;
    font-size: 2.5rem;
    font-weight: bold;
}

.carousel-text p {
    margin: 10px 0 0;
    font-size: 1.2rem;
}

.el-carousel-item {
    height: 780px;
}

.el-carousel__item {
    transition: transform 0.5s ease;
}

.el-carousel__item:hover {
    transform: scale(1.02);
}

/* Responsive Styles */
@media (max-width: 768px) {
    .carousel-text {
        bottom: 10%;
        padding: 15px;
    }

    .carousel-text h2 {
        font-size: 2rem; /* Smaller font for smaller screens */
    }

    .carousel-text p {
        font-size: 1rem; /* Adjust text size */
    }

    .el-carousel-item {
        height: 500px; /* Reduce height for smaller devices */
    }
}

@media (max-width: 480px) {
    .carousel-text {
        bottom: 5%;
        padding: 10px;
    }

    .carousel-text h2 {
        font-size: 1.8rem; /* Further reduce font size for very small screens */
    }

    .carousel-text p {
        font-size: 0.9rem; /* Adjust text size */
    }

    .el-carousel-item {
        height: 300px; /* Compact height for small screens */
    }
}
</style>
