<template>
    <div class="background">
        <div class="animated-lines"></div>
        <div class="card-container">
            <div class="card" v-for="(item, index) in services" :key="index">
                <div class="image-container">
                    <img :src="require(`@/assets/${item.image}`)" alt="Service Image" />
                </div>
                <div class="card-content">
                    <h3>{{ item.title }}</h3>
                    <p>{{ item.description }}</p>
                    <button  class="learn-more-btn" @click="gotoContact">Learn More</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        gotoContact(){
            this.$router.push("/contact");
        }
    },
    data() {
        return {
            services: [
                {
                    image: "therapy.jpg",
                    title: "Therapy Sessions",
                    description: "Individual or group therapy sessions with experienced therapists.",
                },
                {
                    image: "ipd.jpg",
                    title: "Counseling Services",
                    description: "Personalized counseling to address various life challenges.",
                },
                {
                    image: "lab.jpg",
                    title: "Support Groups",
                    description: "Join our support groups and connect with others.",
                },
            ],
        };
    },
};
</script>

<style scoped>
/* Container for the entire background */
.background {
    position: relative;
    background-color: #f3f8fb; /* The background color */
    padding: 40px 20px;
    overflow: hidden; /* Hide the overflow of the animated lines */
}

/* Animated diagonal lines in the background */
.animated-lines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(
        45deg,
        rgba(197, 193, 193, 0.1),
        rgba(233, 231, 231, 0.1) 10px,
        transparent 10px,
        transparent 20px
    );
    animation: move-lines 20s linear infinite;
    z-index: 1; /* Ensure it stays behind the cards */
}

/* Keyframes for moving the lines */
@keyframes move-lines {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 200% 200%;
    }
}

/* Card container and styling */
.card-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px; /* Add space between cards */
    z-index: 2; /* Ensure the cards are on top of the animated lines */
}

/* Card styling */
.card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: calc(33.333% - 40px);
    overflow: hidden;
    text-align: center;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

/* Image styling with zoom effect */
.image-container {
    overflow: hidden;
}

.card img {
    width: 100%;
    height: 300px;
    object-fit: cover; /* Ensure images maintain aspect ratio */
    transition: transform 0.3s ease;
}

.card:hover img {
    transform: scale(1.1);
}

/* Content inside the card */
.card-content {
    padding: 20px;
}

.card h3 {
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #333;
}

.card p {
    margin-bottom: 15px;
    color: #777;
}

.learn-more-btn {
    padding: 10px 20px;
    background-color: #10767b;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.learn-more-btn:hover {
    background-color: #0d5e63;
}

/* Responsive styles */
@media (max-width: 1024px) {
    .card {
        width: calc(50% - 30px); /* Adjust to 2 cards per row */
    }
}

@media (max-width: 768px) {
    .card {
        width: 100%; /* Full-width cards for smaller screens */
        margin: 10px 0;
    }

    .card img {
        height: 250px; /* Adjust image height for smaller screens */
    }
}

@media (max-width: 480px) {
    .card-content {
        padding: 15px;
    }

    .card h3 {
        font-size: 1.3em; /* Adjust font size for smaller screens */
    }

    .card p {
        font-size: 0.9em;
    }

    .learn-more-btn {
        font-size: 0.9em;
        padding: 8px 15px; /* Smaller button for mobile */
    }
}
</style>
