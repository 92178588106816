<template>
  <el-header :style="{ top: navbarVisible ? '0' : '-64px' }" class="axon-navbar">
    <div class="navbar-content">
      <div class="navbar-left">
        <img src="@/assets/axon full logo.png" class="navbar-logo" />
      </div>

      <el-menu :router="true" :default-active="$route.path" mode="horizontal" class="navbar-center" :collapse="isMobile">
        <el-menu-item router="true" index="/">Home</el-menu-item>
        <el-menu-item router="true" index="/about">About</el-menu-item>
        <el-menu-item router="true" index="/services">Services</el-menu-item>
        <el-menu-item router="true" index="/charity">Charity</el-menu-item>
        <el-menu-item router="true" index="/contact">Contact</el-menu-item>
      </el-menu>

      <div class="navbar-right">
        <span class="navbar-phone">Call us: (93) 785-611-999</span>
        <el-button @click="openWhatsApp()" type="primary" class="chat-now-button">Chat Now</el-button>
        <el-button v-if="isMobile" @click="toggleMenu" type="text" class="menu-toggle-btn">
          <i class="el-icon-menu"></i>
        </el-button>
      </div>
    </div>

    <!-- Mobile menu overlay -->
    <div v-if="showMobileMenu" class="mobile-menu-overlay" @click="toggleMenu">
      <el-menu :router="true" :default-active="$route.path" mode="vertical" class="mobile-menu">
        <el-menu-item router="true" index="/">Home</el-menu-item>
        <el-menu-item router="true" index="/about">About</el-menu-item>
        <el-menu-item router="true" index="/services">Services</el-menu-item>
        <el-menu-item router="true" index="/charity">Charity</el-menu-item>
        <el-menu-item router="true" index="/contact">Contact</el-menu-item>
      </el-menu>
    </div>
  </el-header>
</template>

<script>
export default {
  name: "AxonNavbar",
  data() {
    return {
      lastScrollY: 0,
      navbarVisible: true,
      showMobileMenu: false,
      isMobile: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.checkMobileView();
    window.addEventListener('resize', this.checkMobileView);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener('resize', this.checkMobileView);
  },
  methods: {
    handleScroll() {
      const currentScrollY = window.scrollY;
      this.navbarVisible = currentScrollY < this.lastScrollY;
      this.lastScrollY = currentScrollY;
    },
    openWhatsApp() {
      const whatsappUrl = `https://wa.me/930785611999`;
      window.open(whatsappUrl, '_blank');
    },
    toggleMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
    checkMobileView() {
      this.isMobile = window.innerWidth <= 768;
    }
  },
};
</script>

<style scoped>
.axon-navbar {
  position: fixed;
  width: 100%;
  height: 64px;
  line-height: 64px;
  z-index: 10000;
  transition: top 0.3s;
  background-color: #fff;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  
}

.navbar-left {
  margin-bottom: -14px;
  flex: 1;
}

.navbar-logo {
  width: 180px;
  height: 50px;
}

.navbar-center {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar-phone {
  margin-right: 20px;
}

.chat-now-button {
  background-color: #10767b;
  border-color: #10767b;
  color: white;
}

.menu-toggle-btn {
  display: none;
  font-size: 24px;
  color: #10767b;
}

.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-menu {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.el-menu-item {
  padding: 12px 20px;
  font-size: 16px;
}
/* Ensure text color is visible */
.navbar-center .el-menu-item {
  color: #333; /* or another contrasting color */
}

/* Make sure the menu is aligned and not hidden */
.el-menu {
  display: flex !important; /* Ensure it takes up space */
  justify-content: center;
}

.el-menu-item {
  margin: 0 10px; /* Add space between links */
}


@media (max-width: 768px) {
  .navbar-center {
    display: none;
  }
  .navbar-phone{
    display: none;
  }
  .navbar-right {
    display: flex;
    justify-content: flex-start;
  }

  .menu-toggle-btn {
    display: block;
  }
}
</style>
