<template>
  <div class="home-page">
    <AxonNavbar />
    <!-- <div class="content">
      <div class="image-container"> -->
        <carouselSlider class="fade-in" />

        <!-- <div class="text-overlay">
          <h1>Welcome to Axon</h1>
          <p>Your path to better mental health starts here</p>
        </div> -->
      <!-- </div>
    </div> -->
  </div>
  <servicesComp class="fade-in" />
  <whyComp class="fade-in" />

</template>

<script>
import AxonNavbar from './AxonNavbar.vue';
import servicesComp from '../components/servicesComp'
import carouselSlider from '../components/carouselSlider'
import whyComp from '../components/whyComp'
export default {
  name: 'HomePage',
  components: {
    AxonNavbar,
    servicesComp,
    carouselSlider,
    whyComp,
    
  },
  data() {
    return {
      scrollOffset: 0,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.observeSections();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollPosition = window.scrollY;
      this.scrollOffset = scrollPosition * 0.5; // Adjust this factor for more/less parallax effect
    },
     observeSections() {
      const options = {
        threshold: 0.2, // Trigger when 10% of the section is visible
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
          }
        });
      }, options);

      document.querySelectorAll('.fade-in').forEach((section) => {
        observer.observe(section);
      });
    },
  },
};
</script>

<style scoped>

.content {
  padding-top: 64px;
  /* Same as navbar height */
}

.image-container {
  position: relative;
  height: 80vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  width: 100%;
  height: auto;
  max-width: none;
  display: block;
}
.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.in-view {
  opacity: 1;
  transform: translateY(0);
}
/*  */
</style>
