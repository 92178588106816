<template>
  <div id="app">
    <AxonNavbar/>
    <RouterView/>
    <!-- <HomePage /> -->
  <footerComponent/>
  </div>
</template>

<script>
import AxonNavbar from './views/AxonNavbar.vue';
import footerComponent from './components/footerComponent.vue';
export default {
  components: {
    AxonNavbar,
    footerComponent
  },
};
</script>


<style>
body{
  margin:0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f5f5f5;
}
#app{
  margin-top: 40px;
}

</style>
