<template>
    <div class="content">

  <div class="contact-page">
    <div class="contact-form">
      <h2>Contact Us</h2>
      <form>
        <div class="form-group">
          <label for="name">Name:</label>
          <input type="text" id="name" v-model="name" required>
        </div>
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" v-model="email" id="email" required>
        </div>
        <div class="form-group">
          <label for="message">Message:</label>
          <textarea id="message" v-model="message" rows="5" required></textarea>
        </div>
        <button type="submit">Send</button>
      </form>
    </div>
   
  </div>
    </div>

</template>

<script>
// import emailjs from 'emailjs-com';
export default {

  name: 'ContactView',
  data(){
    return{
        name: "",
        email:"",
        message:"",
    }
    
  },
  methods:{
//     sendEmail(){
//         const serviceID = 'your_service_id';
//       const templateID = 'your_template_id';
//       const userID = 'your_user_id';

//       emailjs.send(serviceID, templateID, {
//         to_name: 'Recipient Name',
//         from_name: 'Your Name',
//         message: 'Hello, this is a test email!',
//       }, userID)
//         .then(response => {
//           console.log('Email sent successfully:', response);
//         })
//         .catch(error => {
//           console.error('Failed to send email:', error);
//         });
    
//     },
  }
};
</script>

<style scoped>
.content {
  padding-top: 64px;
  /* Same as navbar height */
}
.contact-page {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.contact-form, .contact-info {
  width: 45%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 15px;
}


label {
  display: block;
  margin-bottom: 5px;
}

input, textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

button {
  background-color: #4CAF50;
  color: white;
  padding:5px;
  border:none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

.contact-info p {
  margin-bottom: 10px;
}
</style>