<template>
  <div class="fade-iin">

  <div class="about-page">
    <!-- Top level meaningful text -->
    <div class="about-text fade-in">
      <p class="about-text-1">About Axon Hospital</p>
      <div class="top-text">
        <h2 class="about-text-desc">Curing with most effective medication <span class="speech">SPEECH</span></h2>
        <div style="">
          <p style="font-size: 18px;">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Enim nam culpa quo, nisi mollitia, quam quae illo molestias eos dicta at. Animi perferendis rem sed doloribus quisquam accusamus fuga aliquid.Enim nam culpa quo, nisi mollitia, quam quae illo molestias eos
          </p>
        </div>
      </div>
    </div>

    <!-- Our Goal Section -->
    <div class="our-goal-section fade-in">
      <div class="goal-text">
        <h2 style="font-size: 35px;">Our Goal</h2>
        <div style="">
          <p>
            Axon is dedicated to saving millions of patients around Afghanistan. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Distinctio ullam animi repellat deleniti sapiente consectetur eius, voluptatem minima quibusdam quaerat adipisci perferendis temporibus quasi, nostrum saepe hic porro? Ut, distinctio!.
          </p>
        </div>
      </div>
      <div class="goal-image-wrapper">
        <img src="@/assets/ourgoal.jpg" alt="Our Goal" class="goal-image" />
      </div>
    </div>

    <!-- Slider Section -->
    <!-- <div class="slider-section fade-in">
      <el-carousel height="800px" indicator-position="outside">
        <el-carousel-item v-for="(item, index) in patients" :key="index">
          <div class="carousel-content">
            <img :src="require(`@/assets/${item.image}`)" alt="Patient Image" class="carousel-image" />
            <div class="carousel-text">
              <h3>{{ item.name }}</h3>
              <p>{{ item.description }}</p>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div> -->

    <!-- Who We Are Section -->
     <div class="who-we-are-section">
      <h2>Who We Are</h2>
      <p>Meet our specialists and learn more about what makes Axon unique.</p>
      <div class="specialists-grid">
        <div v-for="(specialist, index) in specialists" :key="index" class="specialist fade-in">
          <div class="specialist-image-wrapper">
            <img :src="require(`@/assets/${specialist.image}`)" alt="Specialist Image" class="specialist-image" />
          </div>
          <div class="specialist-info">
            <h3>{{ specialist.name }}</h3>
            <h4>{{ specialist.title }}</h4>
            <p>{{ specialist.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      patients: [
        {
          image: 'patient1.avif',
          name: 'Tom',
          description: 'Tom was mentally ill and now is okay.',
        },
        {
          image: 'patient2.avif',
          name: 'Sarah',
          description: 'Sarah received therapy and is doing much better.',
        },
        // Add more patients as needed
      ],
  specialists: [
        {
          image: 'specialist.jpg',
          name: 'Dr. John Doe',
          title: 'Chief Surgeon',
          description: 'Dr. John Doe is a highly skilled surgeon with over 20 years of experience in advanced surgical procedures.',
        },
        {
          image: 'specialist.jpg',
          name: 'Dr. Jane Smith',
          title: 'Psychiatrist',
          description: 'Dr. Jane Smith specializes in mental health and has been helping patients recover from severe conditions for over 15 years.',
        },
        {
          image: 'specialist.jpg',
          name: 'Dr. Emily Johnson',
          title: 'Pediatrician',
          description: 'Dr. Emily Johnson is dedicated to the health and well-being of children, providing compassionate care for all her patients.',
        },
        // Add more specialists as needed
      ],
    };
  },
  mounted() {
    this.observeSections();
  },
  methods: {
    observeSections() {
  const options = { threshold: 0.1 };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      console.log('Observed entry:', entry.target, 'IsIntersecting:', entry.isIntersecting); // Debugging line
      if (entry.isIntersecting) {
        entry.target.classList.add('in-view');
      }
    });
  }, options);

  document.querySelectorAll('.fade-in').forEach((section) => {
    observer.observe(section);
  });
},

  },
};
</script>

<style scoped>
/* General Styles */
.about-page {
  padding: 20px;
}

.about-text {
  margin-top: 20vh;
  margin-left: 30vh;
  padding-bottom: 20vh;
}

.about-text-1 {
  font-size: 22px;
  font-weight: 500;
}

.about-text-desc {
  font-size: 45px;
}

.speech {
  display: block;
  font-size: 50px;
  letter-spacing: 100px;
  color: #059ca1;
}

.our-goal-section {
  padding: 10px;
  display: flex;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
  margin-top: 2%;
}

.goal-text {
  padding: 10px;
  margin-left: 12.5%;
  flex: 1; /* Ensures text takes up available space */
}

.goal-image-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
}

.goal-image {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  object-fit: cover;
  position: relative;
  top: -20px;
}

.slider-section {
  margin-bottom: 50px;
}

.carousel-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carousel-image {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  max-height: 800px;
  object-fit: contain;
  transition: transform 0.5s ease;
}

.carousel-text {
  position: absolute;
  bottom: 20%;
  left: 20%;
  transform: translateX(-50%);
  color: white;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  opacity: 1;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.carousel-image:hover {
  transform: scale(1.05);
}

.carousel-text:hover {
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}

.who-we-are-section {
  text-align: center;
}

.who-we-are-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.who-we-are-section p {
  font-size: 1.2rem;
}

/* Specialist Grid */
.specialists-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 50px;
}

.specialist {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.5s ease;
}

.specialist:hover {
  transform: scale(1.05);
}

.specialist-image-wrapper {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;
}

.specialist-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.specialist-info h3 {
  font-size: 1.5rem;
  margin: 10px 0;
}

.specialist-info h4 {
  font-size: 1.2rem;
  color: #059ca1;
  margin: 5px 0;
}

.specialist-info p {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}

/* Fade-in Animation */
.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.in-view {
  opacity: 1;
  transform: translateY(0);
}


/* Responsive Design */
@media (max-width: 1024px) {
  .about-text {
    margin-top: 10vh;
    margin-left: 5vh;
    padding-bottom: 10vh;
  }

  .about-text-desc {
    font-size: 32px;
  }

  .speech {
    font-size: 36px;
    letter-spacing: 50px;
  }

  .our-goal-section {
    flex-direction: column; /* Stack content vertically */
    text-align: center; /* Center-align text */
  }

  .goal-image {
    width: 300px;
    height: 300px;
  }

  .carousel-text {
    font-size: 1rem;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 768px) {
  .about-text {
    margin-left: 0;
    padding-bottom: 5vh;
  }

  .goal-image {
    width: 250px;
    height: 250px;
  }

  .carousel-text {
    font-size: 0.9rem;
    padding: 10px;
  }

  .specialist-image-wrapper {
    width: 150px;
    height: 150px;
  }

  .specialist-info h3 {
    font-size: 1.2rem;
  }

  .specialist-info h4 {
    font-size: 1rem;
  }

  .specialist-info p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .about-text-desc {
    font-size: 24px;
  }

  .speech {
    font-size: 28px;
    letter-spacing: 20px;
  }

  .carousel-image {
    height: auto;
  }

  .carousel-text {
    font-size: 0.8rem;
    padding: 5px;
  }

  .specialist-info h3 {
    font-size: 1rem;
  }

  .specialist-info h4 {
    font-size: 0.8rem;
  }

  .specialist-info p {
    font-size: 0.8rem;
  }
}
</style>
