<template>
  <section class="why-choose-axon">
    <h2>Why Choose Axon Hospital?</h2>
    <div class="reasons">
      <transition-group name="card-fade" tag="div" class="reason-cards-container">
        <div
          class="reason-card"
          v-for="(reason, index) in reasons"
          :key="index"
          :style="{ animationDelay: `${index * 0.2}s` }"
        >
          <img :src="require(`@/assets/${reason.icon}`)" alt="Reason Icon" class="reason-icon" />
          <h3>{{ reason.title }}</h3>
          <p>{{ reason.description }}</p>
        </div>
      </transition-group>
    </div>
  </section>
</template>


<script>
export default {
  data() {
    return {
      reasons: [
        {
          icon: 'ipd.jpg',
          title: 'Expert Care',
          description: 'Our team consists of highly experienced and compassionate professionals.',
        },
        {
          icon: 'lab.jpg',
          title: 'Personalized Treatment',
          description: 'We provide personalized treatment plans tailored to each individuals needs.',
        },
        {
          icon: 'therapy.jpg',
          title: 'State-of-the-Art Facilities',
          description: 'Our hospital is equipped with modern facilities and advanced technology.',
        },
        {
          icon: 'therapy.jpg',
          title: 'Supportive Environment',
          description: 'We offer a warm and supportive environment for your healing journey.',
        },
      ],
    };
  },
};
</script>
<style scoped>
.why-choose-axon {
    padding: 40px 20px;
    background-color: #f5f5f5;
    text-align: center;
}

.why-choose-axon h2 {
    font-size: 28px;
    margin-bottom: 30px;
    color: #10767b;
}

.reasons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.reason-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Add spacing between cards */
}

.reason-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 0; /* Remove padding */
    width: 100%; /* Full width by default */
    max-width: 300px; /* Restrict maximum width */
    text-align: center;
    opacity: 0;
    transform: translateY(30px); /* Start from below */
    animation: slideIn 0.6s forwards; /* Apply the animation */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden; /* Ensure content doesn't overflow */
}

.reason-card:hover {
    transform: translateY(-10px); /* Slight lift on hover */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
}

.reason-card img {
    width: 100%; /* Full width of the card */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Ensure no inline spacing */
}

.reason-card h3 {
    font-size: 20px;
    color: #10767b;
    margin: 15px 10px 10px;
}

.reason-card p {
    font-size: 16px;
    color: #555;
    margin: 10px 15px;
}

/* Keyframes for slide-in animation */
@keyframes slideIn {
    to {
        opacity: 1;
        transform: translateY(0); /* Slide up to its original position */
    }
}

/* Responsive Design */
@media (max-width: 1024px) {
    .reason-card {
        max-width: 45%; /* Two cards per row */
    }
}

@media (max-width: 768px) {
    .reason-card {
        max-width: 100%; /* Single card per row on small screens */
    }
}

@media (max-width: 480px) {
    .why-choose-axon h2 {
        font-size: 24px; /* Adjust heading size */
    }

    .reason-card h3 {
        font-size: 18px;
    }

    .reason-card p {
        font-size: 14px;
    }
}
</style>
