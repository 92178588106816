import { createRouter, createWebHashHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import AboutView from '../views/AboutView.vue'
import charityView from '../views/charityView.vue'
import ContactView from '@/views/contactView.vue'
import ServicesView from '@/views/servicesView.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/about',
    name: 'about',
    component:AboutView
  },
  {
    path: '/charity',
    name: 'charity',
    component:charityView
  },
    {
    path: '/contact',
    name: 'contact',
    component:ContactView
  },
   {
    path: '/services',
    name: 'services',
    component:ServicesView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
